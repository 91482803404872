import React from 'react'
import PropTypes from 'prop-types'
import Tag from './tag'

function RankingCard({ children }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props })
  )

  return <article className="border-box">{childs}</article>
}

RankingCard.defaultProps = {
  point: 5
}

RankingCard.propTypes = {
  point: PropTypes.number,
  children: PropTypes.node
}

RankingCard.Header = function RankingCardHeader({ tag, children }) {
  return (
    <header className="d-flex align-items-start">
      <h5>{children}</h5>
      <div className="ml-32 flex-shrink-0">
        <Tag className="px-16">{tag}</Tag>
      </div>
    </header>
  )
}

RankingCard.Body = function RankingCardBody({ children }) {
  return <div className="mt-24">{children}</div>
}

RankingCard.Footer = function RankingCardFooter({ url, children }) {
  return (
    <footer className="mt-24 fs-small link c-dark-60">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-hover-red"
      >
        {children}
      </a>
    </footer>
  )
}
export default RankingCard
