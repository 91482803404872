/**
 *
 * @param {'positive'|'negative'} typeOfFactor
 */
export default function(typeOfFactor) {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return
  }
  let targetClass = undefined
  switch (typeOfFactor) {
    case 'positive':
      targetClass = 'positive-jump-to'
      break
    case 'negative':
      targetClass = 'negative-jump-to'
      break
    default:
      return
  }

  const elem = document.getElementsByClassName(targetClass)[0]
  if (elem) {
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
    elem.scroll()
  }
}
