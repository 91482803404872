import React, { useState } from 'react'
import { graphql } from 'gatsby'
import uniqBy from 'lodash/uniqBy'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import Hero from '@v/ranking-factors/hero'
import Filter from '@v/ranking-factors/filters'
import Positive from '@v/ranking-factors/positive'
import Negative from '@v/ranking-factors/negative'

import './index.css'

const Page = ({ data }) => {
  const { page } = data
  const model = new DatoCMSModel(page)
  const title = page.title
  const filters = uniqBy(
    [{ key: 'all', value: page.noFilterText }]
      .concat(
        page.positiveCards.map(card => ({ key: card.tag, value: card.tag }))
      )
      .concat(
        page.negativeCards.map(card => ({ key: card.tag, value: card.tag }))
      ),
    'key'
  )
  const [filterPositive, setFilterPositive] = useState('all')
  const [filterNegative, setFilterNegative] = useState('all')

  // console.log(data, filters)

  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      <Hero
        titleSmall={page.heroTitleSmall}
        titleBig={page.heroTitleBig}
        description={page.heroDescription}
        image={page.heroImage}
        imageAsset={page.heroImageAsset}
        negativeTitle={page.negativeTitle}
        positiveTitle={page.positiveTitle}
      />
      <div className="positive-jump-to"></div>
      <Filter
        filters={filters}
        filter={filterPositive}
        onChangeFilter={filter => setFilterPositive(filter)}
        title={page.positiveTitle}
        description={page.positiveDescription}
      />
      <Positive cards={page.positiveCards} filter={filterPositive} />

      <div className="negative-jump-to"></div>
      <Filter
        filters={filters}
        filter={filterNegative}
        onChangeFilter={filter => setFilterNegative(filter)}
        title={page.negativeTitle}
        description={page.negativeDescription}
      />
      <Negative cards={page.negativeCards} filter={filterNegative} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query RankingFactorsPageQuery($locale: String) {
    page: datoCmsRankingFactorsPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      heroTitleSmall
      heroTitleBig
      heroDescription
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
      noFilterText
      positiveTitle
      positiveDescription
      negativeTitle
      negativeDescription
      positiveCards {
        id
        title
        tag
        description
        footer
        footerUrl
      }
      negativeCards {
        id
        title
        tag
        description
        footer
        footerUrl
      }
    }
  }
`
