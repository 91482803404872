import React from 'react'
import PageHeader from '@c/page-header'
import useScrollIntoFactors from './use-scroll-into-factors'
import ProjectImage from '@/components/project-image'

const Hero = ({
  titleSmall,
  titleBig,
  description,
  image,
  positiveTitle,
  negativeTitle
}) => {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-lg-5 order-1 order-lg-0 mt-40 mt-lg-0">
          <PageHeader topTitle={titleSmall} title={titleBig} />
          <div
            className="my-32"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-auto mb-16">
              <button
                className="btn btn--red btn--round block-on-tight-screen"
                style={{ minWidth: 320 }}
                onClick={() => useScrollIntoFactors('positive')}
              >
                {positiveTitle}
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn--line btn--round block-on-tight-screen"
                style={{ minWidth: 320 }}
                onClick={() => useScrollIntoFactors('negative')}
              >
                {negativeTitle}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 ta-center ta-lg-right">
          {image && <ProjectImage image={image} />}
        </div>
      </div>
    </div>
  )
}

export default Hero
