import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './index.css'

const Tag = ({ children, className }) => {
  return <span className={cn('tag', className)}>{children}</span>
}

Tag.defaultProps = {}

Tag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Tag
