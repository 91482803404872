import React from 'react'
import RankingCard from '@c/ranking-card'

const Positive = ({ filter, cards }) => (
  <div className="container mt-40">
    <div className="row">
      {/* cards */}
      {cards
        .filter(card => filter === 'all' || card.tag === filter)
        .map(card => (
          <div key={card.id} className="col-12 col-lg-6 mb-32">
            <RankingCard>
              <RankingCard.Header tag={card.tag}>
                {card.title}
              </RankingCard.Header>
              <RankingCard.Body>
                <div dangerouslySetInnerHTML={{ __html: card.description }} />
              </RankingCard.Body>
              <RankingCard.Footer url={card.footerUrl}>
                {card.footer}
              </RankingCard.Footer>
            </RankingCard>
          </div>
        ))}
      {/* / cards */}
    </div>
  </div>
)

export default Positive
