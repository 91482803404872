import React from 'react'
import cn from 'classnames'

import PageHeader from '@c/page-header'

const Filters = ({ filter, filters, onChangeFilter, title, description }) => (
  <div className="container mt-80">
    <div className="row">
      <div className="col-12">
        <PageHeader title={title} type="h3" />

        <div
          className="mt-32"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>

    <div className="row mt-40">
      <div className="col-12">
        <div className="d-flex align-items-center flex-wrap">
          {/* title */}
          <span className="h4 c-red-dark mr-24 lh-head-flat">Filtrele:</span>

          {/* buttons - desktop */}
          <div className="d-none d-lg-flex align-items-center flex-wrap">
            {filters.map(item => (
              <button
                key={item.key}
                type="button"
                className={cn(
                  'ranking-filter-button btn btn--round btn--small fw-text-normal m-8',
                  { active: filter === item.key }
                )}
                onClick={() => onChangeFilter(item.key)}
              >
                {item.value}
              </button>
            ))}
          </div>

          {/* dropdown - mobile */}
          <select
            name="filter"
            onChange={e => onChangeFilter(e.target.value)}
            onBlur={e => onChangeFilter(e.target.value)}
            defaultValue={filter}
            className="d-lg-none"
          >
            {filters.map(item => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </div>
)

export default Filters
